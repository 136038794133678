<template>
  <div>
    <base-title title="Eventos">
      <base-button
        @click="drawers.filters.open = !drawers.filters.open"
        design="two"
        :class="isMobile ? 'reverse w-100 mt_10' : 'ml_15'"
      >
        <i class="far fa-sliders-h" v-if="!isMobile" />
        <p class="filter" v-else>Filtragem</p>
      </base-button>
    </base-title>
    <base-empty
      v-if="events.length == 0 && pagination.next == -1"
      title="Você ainda não tem eventos registrados."
      message="Todas os eventos irão aparecer aqui."
    >
      <template slot="image">
        <i class="far fa-list-timeline" />
      </template>
    </base-empty>
    <div
      class="events-day"
      v-else
      v-for="(eventsGroup, eventDayIndex) in events"
      :key="eventDayIndex"
    >
      <p class="date">{{ formatDate(eventsGroup.date) }}</p>
      <div class="events">
        <event-item
          v-for="(event, i) in eventsGroup.items"
          :key="i"
          :event="event"
          @showProfile="showProfile"
        />
      </div>
    </div>
    <infinite-loading
      v-if="pagination.next > -1"
      :identifier="infiniteLoadingId"
      @infinite="getEvents"
      spinner="spiral"
    >
      <span slot="no-more"></span>
      <span slot="spinner">
        <base-loading />
      </span>
    </infinite-loading>
    <CustomerDrawer
      v-if="!!customerSelect"
      :drawer="drawers.customer"
      :customer="customerSelect"
    />
    <FilterDrawer :drawer="drawers.filters" @onFilter="filter" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { mapState } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';
import BaseTitle from '@/components/Title';
import BaseButton from '@/components/Button';
import BaseEmpty from '@/components/Empty';
import BaseLoading from '@/components/Loading';
import EventItem from '@/containers/Events/EventItem';
import CustomerDrawer from '@/containers/Events/CustomerDrawer';
import FilterDrawer from '@/containers/Events/FilterDrawer';
import { EventAPI } from '@/api/EventAPI';

// TODO: Unificar classes
import { EnvironmentAPI } from '@/api/Environment';
import { EnvironmentsAPI } from '@/api/Environments';

dayjs.extend(isToday);

const TYPES = {
  NEW_ACCOUNT: 'new_profile',
  CONVERSION: 'conversion',
  CHANGE_EMAIL: 'email',
  CHANGE_NAME: 'name',
  CHANGE_BIRTHDAY: 'birthday',
  ADD_ADDRESS: 'address',
};

const components = {
  BaseTitle,
  BaseButton,
  BaseEmpty,
  BaseLoading,
  EventItem,
  CustomerDrawer,
  FilterDrawer,
  InfiniteLoading,
};

export default {
  components,
  data() {
    return {
      filters: {},
      selectedTab: 0,
      attribution: null,
      benefit: null,
      phone: null,
      status: 'success',
      step: TYPES.NEW_ACCOUNT,
      showLoading: false,
      rawEvents: [],
      events: [],
      availableSources: [],
      infiniteLoadingId: +new Date(),
      pagination: {
        next: 1,
        limit: 18,
      },
      customerSelect: null,
      drawers: {
        customer: {
          open: false,
          close: false,
        },
        filters: {
          open: false,
          close: true,
        },
      },
    };
  },
  methods: {
    async getEvents($state) {
      const { pagination, filters } = this;

      if (pagination.next === -1) {
        $state && $state.complete();
        return;
      }

      await this.getAvailableSources();

      const sources = this.availableSources.reduce((finalObj, source) => {
        finalObj[source.alias] = source.name;
        return finalObj;
      }, {});

      const eventsResponse = await EventAPI.getEvents(
        filters,
        pagination.next,
        pagination.limit
      );
      const events = eventsResponse.getData({ items: [] });

      this.rawEvents.push(...events.items);
      this.events = this.rawEvents.reduce((finalArray, event) => {
        const date = dayjs(event.created_at).format('YYYY-MM-DD');
        const groupItem = finalArray.find((group) => group.date == date);

        event.source = !sources[event.source_id]
          ? 'Desconhecido'
          : sources[event.source_id];
        if (groupItem) {
          groupItem.items.push(event);
        } else {
          finalArray.push({
            date,
            items: [event],
          });
        }

        return finalArray;
      }, []);

      this.events.forEach((eventGroup) => {
        // Ordenação reversa de datas, mais recente para mais antigas
        eventGroup.items = eventGroup.items.sort((a, b) => {
          return dayjs(a.created_at).isAfter(dayjs(b.created_at)) ? -1 : 1;
        });
      });

      // Ordenação reversa de datas, mais recente para mais antigas
      this.events = this.events.sort((a, b) => {
        return dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1;
      });

      this.pagination.next = events.next.page || -1;
      if (this.pagination.next > -1) {
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }
    },
    async getAvailableSources() {
      this.availableSources = (
        await EnvironmentsAPI.getAvailableSources(false)
      ).getData([]);
    },
    formatDate(date) {
      const d = dayjs(date);
      return `${d.isToday() ? 'Hoje, ' : ''}${d.format('DD/MM/YYYY')}`;
    },
    async showProfile(id) {
      const customerResponse = await EnvironmentAPI.requestCustomersId(id);
      const customer = customerResponse.getData(null);

      if (!customer) {
        alert(customerResponse.getErrors([]));
        return;
      }

      this.customerSelect = customer;
      this.drawers.customer.open = !this.drawers.customer.open;
    },
    async filter(filters) {
      this.filters = filters;
      this.rawEvents = [];
      this.events = [];
      this.pagination.next = 1;
      this.drawers.filters.close = !this.drawers.filters.close;
      this.refreshEvents();
    },

    refreshEvents() {
      this.infiniteLoadingId = +new Date();
    },

    changeRoute(routeName, params) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      this.$router.push({ name: routeName });
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.tabs {
  margin-bottom: 40px;
}
.tabs ul:not(.options-list) {
  display: flex;
  border-bottom: 2px solid rgba(111, 118, 126, 0.15);
}
.tabs a {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  font-size: 12px;
  color: #999999ad;
  padding-bottom: 15px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
}
.tabs a.active {
  color: var(--default);
  border-color: var(--default);
}

@media screen and (max-width: 991px) {
  .tabs {
    margin-bottom: 25px;
  }
}

.events {
  position: relative;
}

.events:before {
  content: '';
  height: calc(100% - 30px);
  top: 0;
  bottom: 0;
  left: 65px;
  margin: auto;
  border-left: 1px dashed rgba(111, 118, 126, 0.5);
  position: absolute;
}

.date {
  color: rgba(235, 235, 235, 0.55);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  font-weight: 900;
}

.phone-box {
  background: rgba(46, 52, 56, 0.55);
  border-radius: 10px;
  padding: 20px 20px 10px;
  margin-top: 15px;
}

.benefit {
  background: #282d32;
  border-radius: 10px;
  padding: 10px 10px 20px;
  margin-bottom: 10px;
}

.benefit .header {
  background: #262b2f;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
  color: #cccccc;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Rubik', sans-serif;
}

.benefit .header .icon {
  background: #23282c;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.benefit .header .icon i {
  font-size: 12px;
  color: #6f767e;
}

.benefit .header .title {
  font-weight: 600;
}

.benefit .header strong {
  color: var(--default);
}

.benefit .header i.fa-chevron-right {
  position: absolute;
  right: 10px;
  color: #6f767e;
  font-size: 10px;
}

.benefit .infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.benefit .infos .info {
  display: flex;
  flex-direction: column;
}

.benefit .infos .info:last-child {
  align-items: flex-end;
}

.benefit .infos .info p {
  color: #cccccc;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.benefit .infos .info span {
  color: rgba(204, 204, 204, 0.65);
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}

.status {
  background: #2d3338;
  border-radius: 10px;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:is(.success, .error) .icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  margin-bottom: 10px;
}

.success > i {
  font-size: 28px;
  margin-bottom: 25px;
}

.success .icon {
  background: #374843;
}

.success i {
  color: #82d997;
}

.error .icon {
  background: rgba(255, 80, 45, 0.15);
  transform: rotate(180deg);
}

:is(.success, .error) p {
  color: rgba(204, 204, 204, 0.75);
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .date {
    margin-bottom: 35px;
  }
  .events {
    padding-left: 25px;
  }
  .events:before {
    border-left: 1px solid rgba(111, 118, 126, 0.25);
    left: 3px;
    height: 100%;
  }
}
</style>
